import React from 'react';

import {
  // Caption,
  AspectRatioBox,
  // Section,
  Panel,
  // Grid,
  // GridItem,
  // VisibleAbove,
  // Curtain,
  // VisibleBelow,
  Centered,
  Caption,
} from '@sdflc/ui';

import { withProviders } from '../hoc';

//import { SubscribeForm } from '../forms';
import { Logo } from '../components';
import { HomeLayout } from '../layouts';
import { colors, siteInfo } from '../config';

const IndexPage = () => {
  const homeBg = siteInfo.assetsBaserUrl + '/background.jpg';
  return (
    <HomeLayout title={siteInfo.title}>
      <Panel backgroundColor={colors.greenishWhite}>
        <AspectRatioBox
          ratio='FREE'
          minHeight='100vh'
          centered
          backgroundColor='transparent'
          backgroundImage={homeBg}
          opacity={0.1}
        >
          <Panel padding='20px' width='' maxWidth={'80%'} zIndex={2}>
            <Centered>
              <Logo square size={Logo.sizes.X_LARGE} />

              {/* <Caption level={4} textAlign='center'>
                IT Consulting and
                <br />
                Software Development Company
              </Caption> */}
            </Centered>
          </Panel>
        </AspectRatioBox>
      </Panel>
    </HomeLayout>
  );
};

export default withProviders(IndexPage);
